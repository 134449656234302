import React from 'react';
import { motion } from 'framer-motion';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-overlay"></div>
      <motion.div
        className="hero-content"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      >
        <h1 className="hero-title">Your Perfect Match is Dreaming of You</h1>
        <motion.p
          className="hero-subtitle"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          Saptapadi Will Lead the Way
        </motion.p>
        
        <div className="cta-buttons">
          <motion.button
            className="cta-button primary"
            whileHover={{ scale: 1.1, boxShadow: "0px 0px 15px rgba(150, 45, 62, 0.7)" }}
            whileTap={{ scale: 0.9 }}
          >
             <a href="#contact">Find Your Match</a>
          </motion.button>
          <motion.button
            className="cta-button secondary"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <a href="#about">Learn More</a>
          </motion.button>
        </div>
      </motion.div>
    </section>
  );
};

export default HeroSection;
