import React from 'react';
import { motion } from 'framer-motion';
import './WhyChooseSection.css';

const WhyChooseSection = () => {
  return (
    <section className="why-choose-section">
      <motion.div
        className="why-choose-content"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        viewport={{ once: true }}
      >
        <h2>Why Choose Saptapadi?</h2>
        <p>
          At Saptapadi, we know that finding the right partner is a deeply personal journey. We are dedicated to guiding you with tailored matching that connects you to individuals who share your values and aspirations.
        </p>
        <p>
          Our experienced team offers personalized support every step of the way, prioritizing genuine connections that lead to lasting relationships. Join our inclusive community, where love is celebrated in all its forms.
        </p>
        <p>
          We believe in marriage as a sacred journey, committed to nurturing bonds built on trust, love, and shared dreams. Choose Saptapadi to discover the love you have been dreaming of and take the first step toward your beautiful love story.
        </p>
      </motion.div>
    </section>
  );
};

export default WhyChooseSection;
