import React from 'react'
import FoundersSection from '../../components/ui/founders/FoundersSection'
import './About.css'
import AboutSection from '../../components/ui/aboutsection/AboutSection'

const About = () => {
  return (
   
    <div className="about">
 <AboutSection/>     
 <FoundersSection/>
    </div>
   
   
  )
}

export default About