import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaFacebook, FaInstagram} from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        
        {/* Logo and Tagline */}
        <motion.div
          className="footer-logo-section"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <img src="/logos/logo.jpeg" alt="Saptapadi Logo" className="footer-logo" />
          <p>Your Journey to a Perfect Match</p>
        </motion.div>
        
        {/* Quick Links */}
        <div className="footer-links">
          <h3>Quick Links</h3>
          <Link to="/" className="footer-link">Home</Link>
          
          <Link to="/about" className="footer-link">About Us</Link>

          <Link to="/services" className="footer-link">Services</Link>
         
          <Link to="/contact" className="footer-link">Contact</Link>
        </div>
        
        {/* Contact Info */}
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <p>Phone: +91-9619201154</p>
          <p>Email: <a href="mailto:Sreyashi@ssaptapadi.com">Sreyashi@ssaptapadi.com</a></p>
        </div>
        
        {/* Social Links */}
        <div className="footer-social">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61567265827904&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://www.instagram.com/ssaptapadi?igsh=dDFjaXlrMm50MjR2" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Saptapadi. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
