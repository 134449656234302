import React from 'react';
import { motion } from 'framer-motion';
import './FoundersSection.css';

const FoundersSection = () => {
  const founders = [
    {
      name: "Sreyashi Desouza",
      title: "Founder, Saptapadi",
      message: `Marriage is one of life’s most sacred journeys, built on the foundations of trust, love, and shared dreams. At Saptapadi, I am devoted to helping you find that special person who completes your journey. With the highest standards of care, confidentiality, and ethics, we ensure that every introduction holds true value, making this chapter in your life as meaningful as it deserves to be.`,
      closing: "With warm regards,",
      avatar: "/images/f1.jpeg" // Dummy avatar for now
    },
    {
      name: "Sanford Desouza",
      title: "Chief Strategy Officer, Saptapadi",
      message: `At Saptapadi, we are honored to be part of your journey towards a meaningful partnership. Our dedication to quality and integrity guides everything we do as we connect you with those who share your vision of love and commitment. Let us help you take that all-important first step towards a lifetime of memories and joy.`,
      closing: "Sincerely,",
      avatar: "/images/f2.jpeg" // Dummy avatar for now
    },
  ];

  return (
    <section className="founders-section">
      <h2>Our Founder’s Vision of Togetherness</h2>
      <div className="founders-container">
        {founders.map((founder, index) => (
          <motion.div
            key={index}
            className="founder-card"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, ease: "easeInOut", delay: index * 0.3 }}
            viewport={{ once: true }}
          >
            <img src={founder.avatar} alt={`${founder.name}'s Avatar`} className="founder-avatar" />
            <div className="founder-content">
              <p className="founder-message">{founder.message}</p>
              <p className="founder-closing">{founder.closing}</p>
              <h3 className="founder-name">{founder.name}</h3>
              <p className="founder-title">{founder.title}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default FoundersSection;
