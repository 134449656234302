import React from 'react';
import { FaUserCheck, FaUsers, FaHeart, FaHandshake, FaLock, FaMapMarkerAlt } from 'react-icons/fa';
import { GiMatchHead, GiPsychicWaves } from 'react-icons/gi';
import { motion } from 'framer-motion';
import './OurServices.css';

const OurServices = () => {
  const services = [
    {
      icon: <GiMatchHead />,
      title: 'Highly Tailored Matches for Deeper Connections',
      description: 'Discover matches that reflect your unique personality, values, and lifestyle. Our process goes beyond typical compatibility checks, focusing on meaningful qualities to create connections that feel natural and lasting.',
    },
    {
      icon: <FaHandshake />,
      title: 'Relationship Coaches Who Guide You Personally',
      description: 'Our relationship coaches provide dedicated support from your first introduction to the growth of a successful relationship, so you can feel assured every step of the way.',
    },
    {
      icon: <FaUserCheck />,
      title: 'Exclusive, Trusted Community of Verified Members',
      description: 'Join a network of serious, like-minded singles, where each profile is carefully verified to uphold authenticity and trust, connecting with equally committed individuals.',
    },
    {
      icon: <GiPsychicWaves />,
      title: 'Psychometric Testing for Deep Compatibility',
      description: 'We take compatibility seriously with in-depth psychometric assessments. By exploring personality traits, values, and preferences, we foster genuine, lasting compatibility.',
    },
    {
      icon: <FaMapMarkerAlt />,
      title: 'Real-World Meetups for Genuine Interaction',
      description: 'Our thoughtfully curated events allow you to meet others face-to-face in safe, enjoyable settings, bringing warmth and depth to every introduction.',
    },
    {
      icon: <FaUsers />,
      title: 'Personalized Matches Based on Cultural and Religious Values',
      description: 'We respect the traditions and values that matter to you, helping you find a partner who understands the unique aspects of your background.',
    },
    {
      icon: <FaHeart />,
      title: 'Commitment to Your Success with a Match Guarantee',
      description: 'If you don’t find your match within the first year, we will extend your membership at no cost, reaffirming our dedication to your happiness.',
    },
    {
      icon: <FaLock />,
      title: 'Complete Privacy and Confidentiality',
      description: 'Your privacy is our priority. Our secure platform protects your information, sharing details only with fully vetted matches.',
    },
  ];

  return (
    <section className="our-services-section">
      <h2>Our Services</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="service-card"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            viewport={{ once: true }}
          >
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default OurServices;
