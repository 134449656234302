import React, { useState } from 'react';
import './Contact.css';
import { motion } from 'framer-motion';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    dob: '',
    education: '',
    profession: '',
    income: '',
    motherTongue: '',
    caste: '',
    email: '',
    phone: '',
    lookingFor: '',
    partnerPreferences: ''
  });
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatusMessage('');

    try {
      const response = await fetch('https://api-service.webartstudio.online/saptapadi/form.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      if (result.status === 'success') {
        setStatusMessage('Your response has been submitted successfully!');
      } else {
        setStatusMessage('Failed to submit. Please try again.');
      }
    } catch (error) {
      setStatusMessage('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="contact-section" id='contact'>
      <motion.div
        className="contact-header"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        viewport={{ once: true }}
      >
        <h2>Contact Us</h2>
        <p>Phone: +91-9619201154</p>
        <p>Email: <a href="mailto:Sreyashi@ssaptapadi.com">sreyashi@ssaptapadi.com</a></p>
      </motion.div>

      <div className="contact-social">
        <h3>Social Media</h3>
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=61567265827904&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="social-icon" />
          </a>
          <a href="https://www.instagram.com/ssaptapadi?igsh=dDFjaXlrMm50MjR2" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="social-icon" />
          </a>
        </div>
      </div>

      <motion.form
        className="inquiry-form"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.3 }}
        viewport={{ once: true }}
        onSubmit={handleSubmit}
      >
        <h3>Enquiry Form</h3>
        <label>
          Name:
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </label>
        <label>
          Date of Birth (DD/MM/YY):
          <input type="text" name="dob" value={formData.dob} onChange={handleChange} required />
        </label>
        <label>
          Education:
          <input type="text" name="education" value={formData.education} onChange={handleChange} required />
        </label>
        <label>
          Profession:
          <input type="text" name="profession" value={formData.profession} onChange={handleChange} required />
        </label>
        <label>
          Annual Income:
          <input type="text" name="income" value={formData.income} onChange={handleChange} required />
        </label>
        <label>
          Mother Tongue:
          <input type="text" name="motherTongue" value={formData.motherTongue} onChange={handleChange} required />
        </label>
        <label>
          Caste:
          <input type="text" name="caste" value={formData.caste} onChange={handleChange} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
        <label>
          Phone:
          <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
        </label>
        <label>
          Looking For:
          <select name="lookingFor" value={formData.lookingFor} onChange={handleChange} required>
            <option value="">Select</option>
            <option value="Bride">Bride</option>
            <option value="Groom">Groom</option>
          </select>
        </label>
        <label>
          Partner Preferences:
          <textarea name="partnerPreferences" rows="4" value={formData.partnerPreferences} onChange={handleChange}></textarea>
        </label>
        <button type="submit" className="submit-btn" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {statusMessage && <p className="status-message">{statusMessage}</p>}
      </motion.form>
    </section>
  );
};

export default Contact;
