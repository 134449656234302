import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './AboutSection.css';

const images = [
  "/images/35.png",
  "/images/40.jpeg",
  "/images/a2.jpeg",
  "/images/41.jpeg",
  "/images/42.jpeg",
];

const AboutSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically advance to the next image every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval); // Clean up on component unmount
  }, []);

  return (
    <section className="about-section" id='about'>
      <motion.div
        className="about-content"
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        viewport={{ once: true }}
      >
        <h2>About Us</h2>
        <p>
          Isn’t marriage a beautiful journey full of promise and excitement? Yet, finding the right partner can often feel challenging. But you don't have to worry! Saptapadi is here to help you discover your perfect match.
        </p>
        <p>
          We believe that marriage is a sacred journey, a profound commitment to love, support, and shared dreams. Recognizing that each individual’s path is unique, we are dedicated to guiding you in your search for that special someone with whom you can create a lifetime of cherished memories and joy.
        </p>
      </motion.div>

      <motion.div
        className="carousel-container"
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        viewport={{ once: true }}
      >
        <AnimatePresence>
          <motion.img
            key={currentIndex}
            src={images[currentIndex]}
            alt="Wedding moments"
            className="carousel-image"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.8 }}
          />
        </AnimatePresence>
      </motion.div>
    </section>
  );
};

export default AboutSection;
