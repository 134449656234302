import React from 'react';
import { motion } from 'framer-motion';
import './MissionVisionSection.css';

const MissionVisionSection = () => {
  return (
    <section className="mission-vision-section">
      <h2>Our Mission & Vision</h2>
      <div className="cards-container">
        <motion.div
          className="card mission-card"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          viewport={{ once: true }}
        >
          <img
            src="https://img.freepik.com/free-photo/hands-indian-bride-groom-intertwined-together-making-authentic-wedding-ritual_8353-10047.jpg"
            alt="Mission"
            className="card-image"
          />
          <h3>Mission</h3>
          <p>
            To create meaningful connections that empower individuals to find their ideal life partners, making the journey of love and marriage fulfilling. With a focus on trust, respect, and compassion, we bring together people whose values, aspirations, and dreams align, building a foundation for lasting relationships rooted in understanding and support.
          </p>
        </motion.div>

        <motion.div
          className="card vision-card"
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          viewport={{ once: true }}
        >
          <img
            src="/images/41.jpeg"
            alt="Vision"
            className="card-image"
          />
          <h3>Vision</h3>
          <p>
            A world where everyone finds love and companionship in a meaningful, lasting partnership. Guided by a commitment to celebrate individuality, cultural heritage, and shared dreams, the vision is to nurture an inclusive, supportive community that brings people closer to a fulfilling and joyful journey together.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default MissionVisionSection;
