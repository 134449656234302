import React from 'react'
import HeroSection from '../../components/ui/herosection/HeroSection'
import AboutSection from '../../components/ui/aboutsection/AboutSection'
import WhyChooseSection from '../../components/ui/whyus/WhyChooseSection'
import MissionVisionSection from '../../components/ui/missionvision/MissionVisionSection'
import ExpertiseSection from '../../components/ui/expertise/ExpertiseSection'
import FoundersSection from '../../components/ui/founders/FoundersSection'
import Contact from '../contact/Contact'

const Home = () => {
  return (
    <>
    <HeroSection/>
    <AboutSection/>
    <MissionVisionSection/>
    <WhyChooseSection/>
    <ExpertiseSection/>
    <FoundersSection/>
    <Contact/>
    </>
  )
}

export default Home