// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import OurServices from './pages/ourservices/OurServices';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import ScrollToTop from './components/scrolltop/ScrollToTop';
import Footer from './components/footer/Footer';

const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<OurServices/>} />
        <Route path="/about" element={<About/>} />
        
        <Route path="/contact" element={<Contact/>} />
        
      </Routes>
     <Footer/>
    </Router>
    
  );
};

export default App;