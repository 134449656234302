import React from 'react';
import './ExpertiseSection.css';
import { FaHeart, FaUserCheck, FaHandsHelping, FaUsers } from 'react-icons/fa';
import { motion } from 'framer-motion';

const ExpertiseSection = () => {
  return (
    <section className="expertise-section">
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        viewport={{ once: true }}
      >
        Our Expertise
      </motion.h2>
      <div className="expertise-cards">
        <motion.div
          className="expertise-card"
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          viewport={{ once: true }}
        >
          <FaHeart className="expertise-icon" />
          <h3>Meaningful Connections</h3>
          <p>
            We specialize in creating meaningful connections that lead to lasting relationships, going beyond traditional matchmaking.
          </p>
        </motion.div>

        <motion.div
          className="expertise-card"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          viewport={{ once: true }}
        >
          <FaUserCheck className="expertise-icon" />
          <h3>Personalized Matching</h3>
          <p>
            Our approach aligns with your unique qualities and aspirations to ensure that each introduction is genuinely meaningful.
          </p>
        </motion.div>

        <motion.div
          className="expertise-card"
          initial={{ opacity: 0, x: 20 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          viewport={{ once: true }}
        >
          <FaHandsHelping className="expertise-icon" />
          <h3>Dedicated Support</h3>
          <p>
            Our experienced team provides guidance, understanding, and warmth at every stage, empowering you in your journey.
          </p>
        </motion.div>

        <motion.div
          className="expertise-card"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          viewport={{ once: true }}
        >
          <FaUsers className="expertise-icon" />
          <h3>Inclusive Community</h3>
          <p>
            Join a community of like-minded individuals who are committed to building relationships based on trust, love, and shared dreams.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default ExpertiseSection;
